export const uniqueIds = { 
    ids:{
          "Group Accident Gold": "6a74bfab-1844-455b-b2d5-4acbf7e7713c",
          "Group Accident Platinum": "a6e80781-7423-46c9-a990-7a994f20ab7a",
          "Group Accident Diamond": "d287f877-6a71-4698-a010-1a00d99d8a90",
          "Individual Accident Gold": "764092ee-3802-4340-acb6-0e08987d71a4",
          "Individual Accident Platinum": "6de0cd0e-97b0-4624-ab06-fd99402db045",
          "Individual Accident Diamond": "61d444ce-ac58-4b01-8d4c-bb5fda5a2969",
          "Group CI Gold": "0944be24-0eda-4ec7-bebc-55400e2d6be7",
          "Group CI Platinum": "02b3aa91-11d4-46bb-82b9-7c077743010b",
          "Group CI Diamond": "5cf7a3f7-2641-4303-962e-695fc471c7c9",
          "Individual CI Gold": "73a0b4d2-003c-4279-a33c-5eff58c76126",
          "Individual CI Platinum": "4431b1c8-38e6-4eeb-b1c2-806a5df5ab3f",
          "Individual CI Diamond": "0c772b19-ddc6-4695-a80b-dabb4b9bbe41",
          "7 3": "c489b9a1-a68b-4c0f-953d-f5c384979630",
          "7 6": "c890f92f-17b2-4170-a971-13fcf9dba7ff",
          "14 3": "f0efabe9-88b2-424c-8789-b6db48fab203",
          "14 6": "cb4ba614-c24d-4bb7-bfd7-f50ad92cd362",
          "30 3": "cd1fe88a-b5fd-4776-8fe3-32d33fdbccea",
          "30 6": "327b1bf1-383c-467b-9ce0-9bf7c2e9b918",

          "3 months - 7/7": "c489b9a1-a68b-4c0f-953d-f5c384979630",
          "3 months - 14/14": "f0efabe9-88b2-424c-8789-b6db48fab203",
          "3 months - 30/30": "cd1fe88a-b5fd-4776-8fe3-32d33fdbccea",
          "6 months - 7/7": "c890f92f-17b2-4170-a971-13fcf9dba7ff",
          "6 months - 14/14": "cb4ba614-c24d-4bb7-bfd7-f50ad92cd362",
          "6 months - 30/30": "327b1bf1-383c-467b-9ce0-9bf7c2e9b918",

          "UL": "d9b65148-dcaa-4b7f-bc23-8d6f6b8d13e8",
      
      "Group Accident Off the Job 90": "5d899e45-dfc9-49fd-9faa-1d2a8fb53ea3",
      "Group Accident Off the Job 180":"8da52d7d-bee0-4b02-b23a-51962dcd5e4f",
      "Group Accident 24 Hour 90": "57f915d2-c85b-4273-abc7-7ebb9fd0bac7",
      "Group Accident 24 Hour 180": "4260ae4d-d973-4d5d-afd2-340a39d708d6",
      "Individual Accident 24 Hour 90": "d4047b23-36be-457f-a12d-eeb9dc0eaa05",
      "Individual Accident 24 Hour 180": "fc5b793c-60b1-40ba-894c-b990c47067c6",
      "Individual Accident Off the Job 90": "788ebdb2-8916-4117-958a-0abeea98a8ea",
      "Individual Accident Off the Job 180": "d1119d08-1c1a-4353-8015-a825aab3da36",
      "Group CI 90": "1be061f1-89d9-4eae-8d21-55d26f4b6eea",
      "Group CI 180": "347b274d-7d58-40ed-81c3-0de412e4486a",
      "Individual CI FL Waive UW 90": "999a667a-4996-45f5-8438-6131635033d1",
      "Individual CI FL Waive UW 180": "c4d88dcc-e99b-4037-8d0c-0b58199b686f",
      "Individual CI FL W/O Waive UW 90": "c62e4b0e-c58f-4893-abde-9c8e7bc1a327",
      "Individual CI FL W/O Waive UW 180": "b28c972e-21da-4ec4-8d94-2c40a6589dba",
      "Individual CI 90 WP ALL NOT FL": "b8448daa-7fd9-409a-862e-e039b1a534df",
      "Individual CI 180 WP ALL NOT FL": "678b8572-aa74-49ab-807a-c42362a749ba",
      "Group DI Class 1 Gold": "5b3b6d19-4a49-4950-afcf-56b874c65e90",
      "Group DI Class 1 Platinum": "8b664194-0793-43ef-b42c-9a13738b76c9",
      "Group DI Class 1 Diamond": "b1c84230-581c-453b-884b-4aaad39b746c",
      "Group DI Class 2 Gold": "51381352-9a34-4d37-bdb3-555c52aaffc7",
      "Group DI Class 2 Platinum": "824e50c1-66b2-4963-b9e9-95fddb87067e",
      "Group DI Class 2 Diamond": "42cc69e6-f492-4473-949a-e45b06ac08e1",
      "Group DI Class 3 Gold": "5b056698-4477-451c-a414-f381e2ff933c",
      "Group DI Class 3 Platinum": "fdef19e0-1589-499c-9d18-179588fe005b",
      "Group DI Class 3 Diamond": "908b36d0-fb8c-4d15-8d90-21578b5a1367",
      "Group DI Class 4 Gold": "66330cf5-4b35-4ef4-9fb2-631e06fb5bf0",
      "Group DI Class 4 Platinum": "a80444e3-af0d-4a27-bcfd-119e0b0b566d",
      "Group DI Class 4 Diamond": "df04c961-5e78-4ca1-9e8f-02d1b91e03ad",
      "UL 90 STD": "41769a34-d804-43fe-acb8-719e9026ce5c",
      "UL 90 GI": "01b03f77-6655-4579-8b68-abe476300fe6",
      "UL 180 STD": "8122e002-8399-46cf-99a0-e7f9e89c2ef6",
      "UL 180 GI": "2fd47682-c5c3-44c7-849c-c40469b8731e",

      "name": "caa711bf-1be3-4879-80dc-49163fbcf534",
      "employer": "84a71035-b7d3-46ea-aab5-3e448a802192",

     'Monthly': '2ccd6d41-fd0a-484b-be54-af49f972a6be',
     'Twice a Month': '69c4fa6e-4cf4-43b3-a728-43833f7d2d98',
     'Bi-Weekly': '7b7d6bda-04be-4268-89f2-cfee0a2282ad',
     'Weekly': 'e03584f7-26c6-44d1-8fbb-7ed095ad7b2b',

     
   'Monthly - 12': '2ccd6d41-fd0a-484b-be54-af49f972a6be',
   'Twice a Month - 24': '69c4fa6e-4cf4-43b3-a728-43833f7d2d98',
   'Bi-Weekly - 26': '7b7d6bda-04be-4268-89f2-cfee0a2282ad',
   'Weekly - 52': 'e03584f7-26c6-44d1-8fbb-7ed095ad7b2b',

     'Chubb Individual Hospital Champion - Diamond $500': "bd91ab2d-77ca-4382-9e3c-2ac2748d6544",
      "Chubb Individual Hospital Champion - Diamond $1000": "1a02c067-b9e6-4d44-97bf-fc2e3a219bb6",
      "Chubb Individual Hospital Champion - Diamond $1500": "1fa6ad1e-343e-49e9-8c14-66469da4dcd8",
      "Chubb Individual Hospital Champion - Gold $500": "509a5e18-3bad-4844-9f4a-fbd30229f742",
      "Chubb Individual Hospital Champion - Gold $1000": "76caed84-0766-4d69-a837-428471ddecea",
      "Chubb Individual Hospital Champion - Gold $1500": "33569234-1ee4-424c-a71d-5a60dfac7b62",
      "Chubb Individual Hospital Champion - Platinum $500": "e09f0fd8-84f8-4522-ae03-d3ce40d78b83",
      "Chubb Individual Hospital Champion - Platinum $1000": "0c60c867-b427-4d3f-9e15-57ea6c8bc442",
      "Chubb Individual Hospital Champion - Platinum $1500": "4bdf1302-f188-49de-806d-2776fedbfbaf",
      "Chubb Individual Hospital Champion - Silver $500": "3e29061c-d015-4f58-8b2d-881d8d032095",
      "Chubb Individual Hospital Champion - Silver $1000": "5085b6dc-9a31-4fba-b326-b8b93c586f02",
      "Chubb Individual Hospital Champion - Silver $1500": "ded1a9a1-9e40-4b27-b58d-650d13eb1eb5",

      "Individual Hospital - 90 EE Base": "f5c4a280-b68e-4963-b043-0580d2c25fbf",
      "Individual Hospital - 180 EE Base": "1d94d6b8-6532-4445-b52d-3b74b88f4a0d",
      "Individual Hospital - 90 ER Base": "0b936aa2-86be-4605-8268-7a575883752c",
      "Individual Hospital - 180 ER Base": "8984d8e1-e5c7-47b5-a315-f1c2986b7420",
      "Individual Hospital - 90 EE Enhanced": "3135e3c8-e549-462a-a275-8e5f8d96950e",
      "Individual Hospital - 180 EE Enhanced": "1ab0c52e-a759-4c52-ad2b-a77a1d4fb817",
      "Individual Hospital - 90 ER Enhanced": "be4770c4-1d61-46b7-9318-fbbc2cdb2600",
      "Individual Hospital - 180 ER Enhanced": "a3368155-dbce-4ae0-8a15-7b77ba15d25a"
    }
}

export const uniqueIdsLevel1 = { 
    ids:{
          "Group Accident Gold": "6a74bfab-1844-455b-b2d5-4acbf7e7713c",
          "Group Accident Platinum": "a6e80781-7423-46c9-a990-7a994f20ab7a",
          "Group Accident Diamond": "d287f877-6a71-4698-a010-1a00d99d8a90",
          "Group CI Gold": "0944be24-0eda-4ec7-bebc-55400e2d6be7",
          "Group CI Platinum": "02b3aa91-11d4-46bb-82b9-7c077743010b",
          "Group CI Diamond": "5cf7a3f7-2641-4303-962e-695fc471c7c9",
          "Group CI Platinum - COC":"0695cc3f-21b4-48c5-9d4c-98dddf8195d3",
          "Group CI Diamond - COC":"0a0576f9-f4bf-4913-911f-f133a7c3e297",
          "Group CI Gold - COC":"92cb960a-aee0-4c2c-9cf1-a29abb428972",
          "UL": "d9b65148-dcaa-4b7f-bc23-8d6f6b8d13e8",
          
          "3 months - 14/14": "f0efabe9-88b2-424c-8789-b6db48fab203",
          "6 months - 14/14": "cb4ba614-c24d-4bb7-bfd7-f50ad92cd362",
          "3 months - 0/7": "9a849a71-80c1-4539-b91c-715c40c750ec",
          "6 months - 0/7": "1d4089b8-247e-4033-93ed-ab23093a0c99",
        //   "7 3": "9a849a71-80c1-4539-b91c-715c40c750ec",
        //   "7 6": "1d4089b8-247e-4033-93ed-ab23093a0c99",


          "Group DI Class 1 Gold": "9d2a40b4-2aa1-4ca3-98c4-50f943b3de17",
          "Group DI Class 1 Diamond": "23f6d27e-c802-43dc-a088-2aedd7bbaf74",
          "Group DI Class 2 Gold": "8576f39e-ac41-4220-9ffb-d44506e34830",
          "Group DI Class 2 Diamond": "f3c5af07-a278-40d6-8fb5-ea4e97134fa2",
          "Group DI Class 3 Gold": "d67c6e4c-d1f4-475a-ab39-be986c7a1835",
          "Group DI Class 3 Diamond": "10d0f7d8-5a89-4b55-971b-2b51ea5e535e",
          "Group DI Class 4 Gold": "74d53415-3b15-4687-9216-8180dcf81ec4",
          "Group DI Class 4 Diamond": "24b758a2-8ec9-418a-b7a2-a49490285d8a",
          
      
      "Group Accident Off the Job 30": "86298472-c99a-4e8d-8884-2309365584e7",
      "Group Accident 24 Hour 30": "7ff416e5-5087-4388-87d1-50b6a6bda123",
      "Group CI 30": "ebfa91a2-eed8-4363-b85d-e0595ff197e3",
      "UL 30 GI": "72c5184f-8851-42e2-839c-f81cdf97d962",

      "name": "cb2e2899-93a7-47ea-bc5d-ef3c1c1c7b65",
      "employer": "5289257f-4dcb-494d-b8e6-dbad225fc4f5",

      'Monthly - 12': '0fe57b9b-38e9-49fc-9dae-40c58e294e94',
      'Twice a Month - 24': 'c6c724a5-32b5-44bd-b416-641a2a54081c',
      'Bi-Weekly - 26': 'd49be2f3-b59a-4539-bf46-1bf3d96c2152',
      'Weekly - 52': '7e659c9d-9c03-480f-b84b-a5cbac8d1bc4',



      "Chubb Individual Hospital Champion - Gold $500": "509a5e18-3bad-4844-9f4a-fbd30229f742",
      "Chubb Individual Hospital Champion - Gold $1000": "76caed84-0766-4d69-a837-428471ddecea",
      "Chubb Individual Hospital Champion - Gold $1500": "33569234-1ee4-424c-a71d-5a60dfac7b62",
      "Chubb Individual Hospital Champion - Platinum $500": "e09f0fd8-84f8-4522-ae03-d3ce40d78b83",
      "Chubb Individual Hospital Champion - Platinum $1000": "0c60c867-b427-4d3f-9e15-57ea6c8bc442",
      "Chubb Individual Hospital Champion - Platinum $1500": "4bdf1302-f188-49de-806d-2776fedbfbaf",


      "Individual Hospital - 30 EE Base": "c786c953-09a4-4dbe-bc69-73ecd2bd05a4"
    }
}

export const uniqueIdsLevel2 = { 
  ids:{
        "Group Accident Gold": "6a74bfab-1844-455b-b2d5-4acbf7e7713c",
        "Group Accident Platinum": "a6e80781-7423-46c9-a990-7a994f20ab7a",
        "Group Accident Diamond": "d287f877-6a71-4698-a010-1a00d99d8a90",
        "Group CI Gold": "0944be24-0eda-4ec7-bebc-55400e2d6be7",
        "Group CI Diamond": "5cf7a3f7-2641-4303-962e-695fc471c7c9",
        "3 months - 0/7": "9a849a71-80c1-4539-b91c-715c40c750ec",
        "3 months - 7/7": "c489b9a1-a68b-4c0f-953d-f5c384979630",
        "6 months - 0/7": "1d4089b8-247e-4033-93ed-ab23093a0c99",
        "6 months - 7/7": "c890f92f-17b2-4170-a971-13fcf9dba7ff",
        "6 months - 14/14": "cb4ba614-c24d-4bb7-bfd7-f50ad92cd362",

        "UL": "d9b65148-dcaa-4b7f-bc23-8d6f6b8d13e8",

    "Group Accident Off the Job 30": "4332bb6e-9914-4fe6-9663-7c18887fd735",
    "Group Accident 24 Hour 30": "b77c4baf-e710-4f34-86fc-2f1b91f8b614",
    "Group CI 30": "43fb1d4b-ddc6-438b-8c00-e0f739de7499",
    "Group DI Class 1 Gold": "6cf82583-134b-4e94-b75d-5040c2e8495c",
    "Group DI Class 1 Diamond": "f166daa9-6788-4c6b-af51-24add4b58b86",
    "Group DI Class 2 Gold": "23533ea5-558f-4d75-b238-689bea132bb1",
    "Group DI Class 2 Diamond": "c185b7f7-0589-4b7a-9c5d-d1d1e9d5bc86",
    "Group DI Class 3 Gold": "fcb0ced4-1760-429b-9661-80aba7fbb73a",
    "Group DI Class 3 Diamond": "9cace553-b076-4161-9181-909f4c6ab9db",
    "Group DI Class 4 Gold": "bc216cba-5640-4645-8875-4985fd960081",
    "Group DI Class 4 Diamond": "66c39bd6-7db5-44d5-ac64-3259f1877209",
    "UL 30 GI": "9856c876-c266-461c-a3e9-cba6747ff69a",
    
    "name": "bcec0686-09ad-42b5-94a2-6465cfba8b2e",
    "employer": "5289257f-4dcb-494d-b8e6-dbad225fc4f5",

   'Monthly - 12': '53e7d84c-ab2b-406f-b80d-426199804691',
   'Twice a Month - 24': '0d4d27f6-879d-4f3a-8cc9-1847c7aaec70',
   'Bi-Weekly - 26': '2acc58e4-b2d5-4ff3-a378-f16ae8780a5a',
   'Weekly - 52': '962193a3-c454-40e7-a126-09b6f4e172fd',


    "Chubb Individual Hospital Champion - Gold $500": "509a5e18-3bad-4844-9f4a-fbd30229f742",
    "Chubb Individual Hospital Champion - Gold $1000": "76caed84-0766-4d69-a837-428471ddecea",
    "Chubb Individual Hospital Champion - Gold $1500": "33569234-1ee4-424c-a71d-5a60dfac7b62",
    
    "Individual Hospital - 30 EE Enhanced": "9e6de097-42d4-4b56-9a30-cd4515d3ba5c",
  }
}

export const uniqueIdsLevel3 = { 
  ids:{
        "Group Accident Gold": "6a74bfab-1844-455b-b2d5-4acbf7e7713c",
        "Group Accident Platinum": "a6e80781-7423-46c9-a990-7a994f20ab7a",
        "Group Accident Diamond": "d287f877-6a71-4698-a010-1a00d99d8a90",
        "Group CI Gold": "0944be24-0eda-4ec7-bebc-55400e2d6be7",
        "Group CI Diamond": "5cf7a3f7-2641-4303-962e-695fc471c7c9",
        
        "3 months - 7/7": "c489b9a1-a68b-4c0f-953d-f5c384979630",
        "3 months - 0/7": "9a849a71-80c1-4539-b91c-715c40c750ec",
        "6 months - 7/7": "c890f92f-17b2-4170-a971-13fcf9dba7ff",
        "6 months - 14/14": "cb4ba614-c24d-4bb7-bfd7-f50ad92cd362",
        "6 months - 0/7": "1d4089b8-247e-4033-93ed-ab23093a0c99",

        "UL": "d9b65148-dcaa-4b7f-bc23-8d6f6b8d13e8",
    
        "Group Accident Off the Job 30": "e69feae4-473b-4169-ab00-0579779fd28d",
        "Group Accident 24 Hour 30": "3643aa1a-5805-4ca3-8bfe-250de0982913",
        "Group CI 30": "3c15b2de-7711-46c8-a788-f9cacd39e9d8",
    "Group DI Class 1 Gold": "999446e1-1f49-45f3-a5a6-90ab1e22833c",
    "Group DI Class 1 Diamond": "8955ad08-b13c-4e5a-bf33-9e078b7472fb",
    "Group DI Class 2 Gold": "327b8ca5-cd82-41a4-a7b0-1506506c5023",
    "Group DI Class 2 Diamond": "82bb457a-9e7c-4af4-8202-a4310faad916",
    "Group DI Class 3 Gold": "f36d54b3-f55e-4dd3-94f4-7c08753292b1",
    "Group DI Class 3 Diamond": "45f68c5c-2013-47c3-8eb2-67f7a31f129e",
    "Group DI Class 4 Gold": "6faa6a2e-0aab-4dbc-81d7-a5ec0b7a1027",
    "Group DI Class 4 Diamond": "175608df-c4c6-49ee-831c-1b380e1cc710",
    "UL 30 GI": "4ccfac30-708e-4a30-81ef-9679d6f295f8",

    "name": "b5e39474-0551-4303-b564-0e55d9372855",
    "employer": "5289257f-4dcb-494d-b8e6-dbad225fc4f5",

   'Monthly': '2f619c9b-e4b5-4542-a052-3c28d1c82056',
   'Twice a Month': '92337955-443e-4e73-804a-0de9465844ac',
   'Bi-Weekly': '95b23745-24b3-42b5-b133-c08aff293af3',
   'Weekly': 'be909b29-0473-4ca1-b4e0-b617667a40bb',

   
 'Monthly - 12': '2f619c9b-e4b5-4542-a052-3c28d1c82056',
 'Twice a Month - 24': '92337955-443e-4e73-804a-0de9465844ac',
 'Bi-Weekly - 26': '95b23745-24b3-42b5-b133-c08aff293af3',
 'Weekly - 52': 'be909b29-0473-4ca1-b4e0-b617667a40bb',

 
    "Chubb Individual Hospital Champion - Platinum $500": "e09f0fd8-84f8-4522-ae03-d3ce40d78b83",
    "Chubb Individual Hospital Champion - Platinum $1000": "0c60c867-b427-4d3f-9e15-57ea6c8bc442",
    "Chubb Individual Hospital Champion - Platinum $1500": "4bdf1302-f188-49de-806d-2776fedbfbaf",
    
    "Individual Hospital - 30 EE Enhanced": "3cd92fca-d308-4e6c-9968-7a9ce6d86444",
    
  }
}



export const uniqueIdsGeneric = { 
  ids:{
        "Group Accident Gold": "",
        "Group Accident Platinum": "",
        "Group Accident Diamond": "",
        "Individual Accident Gold": "",
        "Individual Accident Platinum": "",
        "Individual Accident Diamond": "",
        "Group CI Gold": "",
        "Group CI Platinum": "",
        "Group CI Diamond": "",
        "Individual CI Gold": "",
        "Individual CI Platinum": "",
        "Individual CI Diamond": "",
        "7 3": "",
        "7 6": "",
        "14 3": "",
        "14 6": "",
        "30 3": "",
        "30 6": "",

        "3 months - 7/7": "",
        "3 months - 14/14": "",
        "3 months - 30/30": "",
        "6 months - 7/7": "",
        "6 months - 14/14": "",
        "6 months - 30/30": "",

        "UL": "",
    
    "Group Accident Off the Job 90": "",
    "Group Accident Off the Job 180":"",
    "Group Accident 24 Hour 90": "",
    "Group Accident 24 Hour 180": "",
    "Individual Accident 24 Hour 90": "",
    "Individual Accident 24 Hour 180": "",
    "Individual Accident Off the Job 90": "",
    "Individual Accident Off the Job 180": "",
    "Group CI 90": "",
    "Group CI 180": "",
    "Individual CI FL Waive UW 90": "",
    "Individual CI FL Waive UW 180": "",
    "Individual CI FL W/O Waive UW 90": "",
    "Individual CI FL W/O Waive UW 180": "",
    "Individual CI 90 WP ALL NOT FL": "",
    "Individual CI 180 WP ALL NOT FL": "",
    "Group DI Class 1 Gold": "",
    "Group DI Class 1 Platinum": "",
    "Group DI Class 1 Diamond": "",
    "Group DI Class 2 Gold": "",
    "Group DI Class 2 Platinum": "",
    "Group DI Class 2 Diamond": "",
    "Group DI Class 3 Gold": "",
    "Group DI Class 3 Platinum": "",
    "Group DI Class 3 Diamond": "",
    "Group DI Class 4 Gold": "",
    "Group DI Class 4 Platinum": "",
    "Group DI Class 4 Diamond": "",
    "UL 90 STD": "",
    "UL 90 GI": "",
    "UL 180 STD": "",
    "UL 180 GI": "",

    "name": "",
    "employer": "",

   'Monthly': '',
   'Twice a Month': '',
   'Bi-Weekly': '',
   'Weekly': '',

   
 'Monthly - 12': '',
 'Twice a Month - 24': '',
 'Bi-Weekly - 26': '',
 'Weekly - 52': '',

   'Chubb Individual Hospital Champion - Diamond $500': "",
    "Chubb Individual Hospital Champion - Diamond $1000": "",
    "Chubb Individual Hospital Champion - Diamond $1500": "",
    "Chubb Individual Hospital Champion - Gold $500": "",
    "Chubb Individual Hospital Champion - Gold $1000": "",
    "Chubb Individual Hospital Champion - Gold $1500": "",
    "Chubb Individual Hospital Champion - Platinum $500": "",
    "Chubb Individual Hospital Champion - Platinum $1000": "",
    "Chubb Individual Hospital Champion - Platinum $1500": "",
    "Chubb Individual Hospital Champion - Silver $500": "",
    "Chubb Individual Hospital Champion - Silver $1000": "",
    "Chubb Individual Hospital Champion - Silver $1500": "",

    "Individual Hospital - 90 EE Base": "",
    "Individual Hospital - 180 EE Base": "",
    "Individual Hospital - 90 ER Base": "",
    "Individual Hospital - 180 ER Base": "",
    "Individual Hospital - 90 EE Enhanced": "",
    "Individual Hospital - 180 EE Enhanced": "",
    "Individual Hospital - 90 ER Enhanced": "",
    "Individual Hospital - 180 ER Enhanced": ""
  }
}